import React from 'react';
import { Helmet } from 'react-helmet-async';

import './ErrorBlock.scss';

export interface ErrorBlockProps {
  metaTitle: string;
  title: React.ReactNode;
  description: React.ReactNode;
  statusCode?: string;
}

export const ErrorBlock: React.FC<ErrorBlockProps> = ({ metaTitle, title, description, statusCode = '404' }) => (
  <>
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Helmet title={metaTitle} />
    }
    <div className="error-block">
      <div className="error-block__content">
        <h2 className="error-block__title">{title}</h2>
        <p className="error-block__description">{description}</p>
      </div>
      <div className={`error-block__status-code error-block__status-code--${statusCode}`}>{statusCode}</div>
    </div>
  </>
);
