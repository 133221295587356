import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import { Loader } from '../../components/UI/Loader';
import { apolloClient } from '../../graphql';
import { routes } from '../../routes';
import i18next from '../../i18n';
import { AppProvider } from '../../contexts/app';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const App: React.FC = () => (
  <ApolloProvider client={apolloClient}>
    <I18nextProvider i18n={i18next}>
      <Suspense
        fallback={
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
            <Loader />
          </div>
        }
      >
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <HelmetProvider>
            <CookiesProvider>
              <BrowserRouter>
                <AppProvider>{renderRoutes(routes)}</AppProvider>
              </BrowserRouter>
            </CookiesProvider>
          </HelmetProvider>
        }
      </Suspense>
    </I18nextProvider>
  </ApolloProvider>
);
