import React from 'react';
import ReactModal from 'react-modal';

import './Modal.scss';

export interface ModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  title: string;
  content: string;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onCloseModal, title, content, children }) => (
  <ReactModal isOpen={isOpen} onRequestClose={onCloseModal} className="modal" overlayClassName="modal-overlay">
    <>
      <h2 className="modal__title" data-test={'modalTitle'}>
        {title}
      </h2>
      <p className="modal__content">{content}</p>
      {children}
    </>
  </ReactModal>
);
