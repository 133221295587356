import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router';
import { matchRoutes } from 'react-router-config';

import { STORAGE_KEYS } from '../../constants';
import { reducer } from './reducer';
import { AppContext, AppContextInterface } from './context';
import {
  AnswerInput,
  AnswerKeyEnum,
  SendAnswersMutation,
  SendNextStepMutation,
  SessionFragment,
  SessionStatusEnum,
  SetFragment,
  SetFragmentDoc,
  StartTestMutation,
  StepTypeEnum,
  UpdateLastInfoOfSessionMutation,
  useResetTestMutation,
  useSendAnswersMutation,
  useSendNextStepMutation,
  useSessionLazyQuery,
  useStartTestMutation,
  useUpdateLastInfoOfSessionMutation,
} from '../../graphql/operations';
import { actions } from './action';
import { routes } from '../../routes';
import { paths } from '../../paths';
import {
  getCurrentAudioPlaying,
  getNextAudioElementBySession,
  getPathTestBySession,
  playAudio as playAudioBase,
  stopAudiosPlaying as stopAudiosPlayingBase,
} from '../../utils';

export const AppProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  // we cannot use useParams for lang here because Router component is inside AppProvider, so AppProvider does not have the router context
  const lang = location.pathname.slice(1, 3);
  const currentRoute = matchRoutes(routes, location.pathname).find((matchRoute) => matchRoute.match.isExact)?.route;
  const [state, dispatch] = useReducer(reducer, {
    appIsLoading: true,
    currentAudioIsPreloaded: false,
    nextAudioIsPreloaded: false,
    preloadedAudios: {},
    answers: [],
    audioVolume: localStorage.getItem(STORAGE_KEYS.AUDIO_VOLUME)
      ? parseFloat(localStorage.getItem(STORAGE_KEYS.AUDIO_VOLUME))
      : 1,
    jwtToken: localStorage.getItem(STORAGE_KEYS.JWT_TOKEN),
  });
  const [audioElements, setAudioElements] = useState<AppContextInterface['audioElements']>({});

  const optimisticResponse = {
    updateSessionResult: {
      success: true,
      __typename: 'Updated',
    },
  };

  const [fetchSession, fetchSessionResult] = useSessionLazyQuery();
  const [startTestMutation] = useStartTestMutation({
    optimisticResponse: optimisticResponse as StartTestMutation,
    // optimistic UI: since next step was already preloaded, we can set it in apollo cache before we retrieved it from the backend
    update(cache) {
      cache.modify({
        id: cache.identify(state.session),
        fields: {
          status: () => SessionStatusEnum.InProgress,
          currentStep: (_, { toReference }) => toReference(state.session.nextStep),
          nextStep: () => null,
        },
        broadcast: true,
      });
    },
    refetchQueries: ['Session'],
  });
  const [sendNextStepMutation] = useSendNextStepMutation({
    optimisticResponse: optimisticResponse as SendNextStepMutation,
    // optimistic UI: since next step was already preloaded, we can set it in apollo cache before we retrieved it from the backend
    update(cache) {
      cache.modify({
        id: cache.identify(state.session),
        fields: {
          currentStep: (_, { toReference }) => toReference(state.session.nextStep),
          nextStep: () => null,
        },
        broadcast: true,
      });
    },
    refetchQueries: ['Session'],
  });
  const [sendAnswersMutation] = useSendAnswersMutation({
    optimisticResponse: optimisticResponse as SendAnswersMutation,
    // optimistic UI: since next step was already preloaded, we can set it in apollo cache before we retrieved it from the backend
    update(cache) {
      cache.modify({
        id: cache.identify(state.session),
        fields: {
          status: () => (state.session.nextStep ? SessionStatusEnum.InProgress : SessionStatusEnum.Completed),
          currentStep: (_, { toReference }) => (state.session.nextStep ? toReference(state.session.nextStep) : null),
          nextStep: () => null,
        },
        broadcast: true,
      });
    },
    refetchQueries: ['Session'],
  });
  const [resetTestMutation] = useResetTestMutation({
    refetchQueries: ['Session'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      localStorage.removeItem(STORAGE_KEYS.LAST_INFO_OF_SESSION);
    },
  });
  const [updateLastInfoOfSessionMutation] = useUpdateLastInfoOfSessionMutation({
    optimisticResponse: optimisticResponse as UpdateLastInfoOfSessionMutation,
    // optimistic UI: since get last info of session, we can set it in apollo cache (currentAudioTime, answers) before we retrieved it from the backend
    update(cache, _, { variables, context }) {
      cache.modify({
        id: cache.identify({ __typename: 'Session', id: context.sessionId }),
        fields: {
          currentAudioTime: () => variables.currentAudioTime,
          currentStep: (existingCurrentStepRef) => {
            const currentStep = cache.readFragment<SetFragment>({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              id: existingCurrentStepRef.__ref,
              fragment: SetFragmentDoc,
            });

            if (currentStep?.__typename === 'Set') {
              const newCurrentStep = { ...currentStep };
              newCurrentStep.questions = [...newCurrentStep.questions].map((question) => {
                const newQuestion = { ...question };
                const currentAnswer = (variables.answers as AnswerInput[]).find((answer) => answer.questionId);
                if (currentAnswer) {
                  newQuestion.answerKeySent = currentAnswer.answerKey;
                }

                return newQuestion;
              });

              return cache.writeFragment({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: existingCurrentStepRef.__ref,
                data: newCurrentStep,
                fragment: SetFragmentDoc,
              });
            }

            return existingCurrentStepRef;
          },
        },
        broadcast: true,
      });
    },
    refetchQueries: ['Session'],
  });

  const startTest = () => startTestMutation();

  const nextStep = () => {
    localStorage.removeItem(STORAGE_KEYS.LAST_INFO_OF_SESSION);
    window.scrollTo(0, 0);
    if (state.session.currentStep.stepType === StepTypeEnum.Part) {
      sendNextStepMutation();
    } else if (state.session.currentStep.stepType === StepTypeEnum.Set) {
      sendAnswersMutation({ variables: { answers: state.answers } });
    }
  };

  const sendAnswer = (options: { questionId: number; answerKey: AnswerKeyEnum }) => {
    dispatch(actions.updateAnswer(options));
  };

  const resetTest = () => {
    dispatch(actions.setIsLoading(true));
    resetTestMutation();
  };

  const isRouteTest = useMemo(
    () =>
      currentRoute &&
      [paths.TEST_INTRO, paths.TEST_PART, paths.TEST_SET, paths.TEST_CONCLUSION].includes(currentRoute.path as string),
    [currentRoute],
  );

  useEffect(() => {
    if (!state?.jwtToken) {
      dispatch(actions.setIsLoading(false));
      if (isRouteTest) {
        dispatch(actions.clearSession());
        history.replace(
          generatePath(paths.ERROR, {
            lang,
            statusCode: 404,
          }),
        );

        return;
      }
    }

    if (state?.jwtToken) {
      dispatch(actions.setIsLoading(true));
      fetchSession();

      return;
    }
  }, [state?.jwtToken]);

  // every time anwsers are selected, if there is an audio playing, add a beforeunload listener to save the current audio when the page is refreshed
  useEffect(() => {
    const currentAudioPlaying = getCurrentAudioPlaying(audioElements);
    if (!currentAudioPlaying) {
      return;
    }

    const updateLastInfoOfSession = () => {
      const lastInfoOfSession = JSON.stringify({
        currentTime: currentAudioPlaying.currentTime,
        answers: state.answers || [],
      });
      localStorage.setItem(STORAGE_KEYS.LAST_INFO_OF_SESSION, lastInfoOfSession);
    };

    const isIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    const eventName = isIOS ? 'pagehide' : 'beforeunload';

    window.addEventListener(eventName, updateLastInfoOfSession);

    return () => {
      window.removeEventListener(eventName, updateLastInfoOfSession);
    };
  }, [state.modalIsOpened, audioElements, state.answers]);

  const preloadAudio = useCallback(
    (audioFileUrl: string): Promise<ArrayBuffer> => {
      dispatch(actions.setPreloadedAudio(audioFileUrl, false));

      return (
        fetch(audioFileUrl)
          // force download audio, to be sure it's saved in the browser's cache
          .then((response) => response.arrayBuffer())
          .then((response) => {
            dispatch(actions.setPreloadedAudio(audioFileUrl, true));

            return response;
          })
      );
    },
    [dispatch],
  );

  const stopAudiosPlaying = useCallback((): number => {
    return stopAudiosPlayingBase(audioElements);
  }, [audioElements]);

  const playAudio = useCallback(
    (audio: HTMLAudioElement, resumeAudio?: boolean) => {
      stopAudiosPlaying();
      playAudioBase(audio, {
        volume: state.audioVolume,
        currentTime: resumeAudio ? state?.session?.currentAudioTime : 0,
      });
    },
    [stopAudiosPlaying, state.audioVolume, state?.session?.currentAudioTime],
  );

  const onEndedAudio = useCallback(
    (audio: HTMLAudioElement) => {
      nextStep();
      playAudio(audio);
    },
    [nextStep, playAudio],
  );

  // when current step or next step audio is fetched, preload the corresponding audio file
  useEffect(() => {
    const currentAudioFileUrl = state.session?.currentStep?.audioFileUrl;
    const nextAudioFileUrl = state.session?.nextStep?.audioFileUrl;
    if (currentAudioFileUrl && !Object.keys(state.preloadedAudios).includes(currentAudioFileUrl)) {
      preloadAudio(currentAudioFileUrl);
    }
    if (nextAudioFileUrl && !Object.keys(state.preloadedAudios).includes(nextAudioFileUrl)) {
      preloadAudio(nextAudioFileUrl);
    }
  }, [state.preloadedAudios, state.session?.currentStep?.audioFileUrl, state.session?.nextStep?.audioFileUrl]);

  // create audio html elements in the state each time audio files are preloaded
  useEffect(() => {
    if (!state.currentPreloadedAudioFileUrl || audioElements[state.currentPreloadedAudioFileUrl]) {
      return;
    }

    setAudioElements({
      ...audioElements,
      [state.currentPreloadedAudioFileUrl]: new window.Audio(state.currentPreloadedAudioFileUrl),
    });
  }, [state.currentPreloadedAudioFileUrl, audioElements]);

  // open warning modal and stop audio if history back/forward buttons are used
  useEffect(() => {
    const currentLocation: Pick<Location, 'pathname' | 'search'> = {
      pathname: null,
      search: null,
    };

    const unlisten = history.listen((newLocation, action) => {
      if (isRouteTest) {
        const locationChanges =
          newLocation.pathname !== currentLocation.pathname || newLocation.search !== currentLocation.search;
        if (action === 'PUSH') {
          if (locationChanges) {
            // Save new location
            currentLocation.pathname = newLocation.pathname;
            currentLocation.search = newLocation.search;

            // Clone location object and push it to history
            history.push({
              pathname: newLocation.pathname,
              search: newLocation.search,
            });
          }
        } else {
          // If a "POP" action event occurs,
          // Send user back to the originating location
          history.go(1);
          if (currentRoute && [paths.TEST_PART, paths.TEST_SET].includes(currentRoute.path as string)) {
            const lastAudioPlayedTime = stopAudiosPlaying();
            dispatch(
              actions.openModal({
                currentAudioTime: lastAudioPlayedTime,
              }),
            );
          }
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history, isRouteTest, currentRoute, stopAudiosPlaying, dispatch]);

  // every time the audio volume is changed via the UI, set the corresponding audio volume in the audio html element
  useEffect(() => {
    const currentAudioPlaying = getCurrentAudioPlaying(audioElements);
    if (currentAudioPlaying && currentAudioPlaying?.volume !== state.audioVolume) {
      currentAudioPlaying.volume = state.audioVolume;
    }
  }, [audioElements, state.audioVolume]);

  // every time we click on next step, add an onEnded listener on the current audio to automatically switch to next step when current audio is ended
  useEffect(() => {
    const currentAudioPlaying = getCurrentAudioPlaying(audioElements);
    if (!currentAudioPlaying) {
      return;
    }

    let ended = () => nextStep();
    const nextAudioElement = getNextAudioElementBySession(state?.session, audioElements);
    if (nextAudioElement) {
      ended = () => onEndedAudio(nextAudioElement);
    }

    currentAudioPlaying.addEventListener('ended', ended, { once: true });

    return () => {
      currentAudioPlaying.removeEventListener('ended', ended);
    };
  }, [state?.session?.nextStep?.stepNumber, audioElements, onEndedAudio]);

  // redirect to the right URL depending on the real state of your session from the backend
  useEffect(() => {
    if (state?.session && isRouteTest) {
      const pathTest = getPathTestBySession({ session: state.session, lang });
      const badLocation = location.pathname.substring(3) !== pathTest.substring(3);
      if (badLocation) {
        history.replace(pathTest);
      }
    }
  }, [isRouteTest, state.session, location.pathname, lang]);

  useEffect(() => {
    // redirect to error page and clear current user session if the backend returns an error
    if (fetchSessionResult?.error?.graphQLErrors?.[0]) {
      const firstGraphQLError = fetchSessionResult.error.graphQLErrors[0];
      dispatch(actions.clearSession());
      history.replace(
        generatePath(paths.ERROR, {
          lang,
          statusCode: (firstGraphQLError?.extensions?.exception as { status: number })?.status?.toString() || 500,
        }),
      );

      return;
    }

    const me = fetchSessionResult?.data?.me;
    const sessionResult = fetchSessionResult?.data?.sessionResult;
    if (!me && !sessionResult?.__typename) return;

    // redirect to error page and clear current user session if the backend returns an error
    if (sessionResult.__typename !== 'Session') {
      switch (sessionResult.__typename) {
        case 'NotFoundError':
          dispatch(actions.clearSession());
          history.replace(generatePath(paths.ERROR, { lang }));

          return;
      }
    }

    const session = sessionResult as SessionFragment;
    const sessionNotExistInState = !state?.session && session;
    const currentStepIsUpdated = state?.session?.currentStep?.stepNumber !== session?.currentStep?.stepNumber;
    const nextStepIsUpdated = state?.session?.nextStep?.stepNumber !== session?.nextStep?.stepNumber;

    // when session is fetched from graphql backend the first time, on init, then set the session in the app state
    if (sessionNotExistInState) {
      if (localStorage.getItem(STORAGE_KEYS.LAST_INFO_OF_SESSION)) {
        const lastInfoOfSession = JSON.parse(localStorage.getItem(STORAGE_KEYS.LAST_INFO_OF_SESSION));
        if (lastInfoOfSession?.currentTime) {
          updateLastInfoOfSessionMutation({
            variables: {
              currentAudioTime: lastInfoOfSession.currentTime,
              answers: lastInfoOfSession?.answers || [],
            },
            context: {
              sessionId: session.id,
            },
          });
          localStorage.removeItem(STORAGE_KEYS.LAST_INFO_OF_SESSION);

          return;
        }
      }
      dispatch(actions.initMeAndSession({ session, me }));
      history.push(getPathTestBySession({ session, lang }));
      // when session is fetched from graphql backend, then reset the session in the app state
    } else if (currentStepIsUpdated && !session?.currentStep?.stepNumber) {
      dispatch(actions.resetSession({ session }));
      history.push(getPathTestBySession({ session, lang }));
      // when current step is set in apollo cache in the optimistic ui case, then update the current step in app state
    } else if (currentStepIsUpdated) {
      dispatch(actions.updateSession({ session }));
      history.push(getPathTestBySession({ session, lang }));
      // when next step is fetched from graphql backend then set next step in the app state
    } else if (nextStepIsUpdated || session?.currentStep?.isLastStep) {
      dispatch(actions.updateNextStep({ nextStep: session.nextStep }));
    }
  }, [
    state?.session?.currentStep?.stepNumber,
    state?.session?.nextStep?.stepNumber,
    fetchSessionResult?.error,
    fetchSessionResult?.data?.me,
    fetchSessionResult?.data?.sessionResult,
  ]);

  return (
    <AppContext.Provider
      value={{
        ...state,
        currentRoute,
        audioElements,
        playAudio,
        stopAudiosPlaying,
        startTest,
        nextStep,
        sendAnswer,
        resetTest,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
